import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/spindle.ameba.design/spindle.ameba.design/src/components/Layout.tsx";
import SEO from '../../../components/SEO';
import IconList from '../../../components/IconList';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageTitle = makeShortcode("PageTitle");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="アイコン一覧 - アイコン" mdxType="SEO" />
    <PageTitle title="アイコン一覧" enTitle="Icons" mdxType="PageTitle" />
    <IconList mdxType="IconList" />
    <p>{`最新のアイコンは`}<a parentName="p" {...{
        "href": "https://github.com/openameba/spindle/blob/main/packages/spindle-icons/docs/icons.md"
      }}>{`GitHub`}</a>{`から確認してください。`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      